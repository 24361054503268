<template>
  <div id="wrapper" class="bg-w noto-sans fc-b">

    <div class="pt-3 mt-3 container">

      <div class="pt-3 df-center" style="justify-content: space-between;">
        <div class="top-title df-center">
          <h4 class="mr-1">하우머치</h4>
          <span>(온라인 견적요청)</span>
        </div>

        <div class="top-title df-center">
          <span class="fc-g">견적요청 생성</span>
        </div>
      </div>
      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="mt-4 mb-2">
        <h4>{{ projectName }}</h4>
      </div>

      <div class="px-3" style="margin: 2rem 0 1.252rem 0;">
        <b-table
            head-variant="white"
            :items="resources"
            :fields="fields"
            sort-icon-left
            responsive="sm"
            class="items-table"
        ></b-table>
      </div>

      <div class="py-5 df-center" style="justify-content: center; color: #474747;">
        <span>최저가로 부탁드립니다.</span>
      </div>

      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-end">
        <b-button class="mt-2 btn-howcost" style="width: 26%;">견적 신청하기</b-button>
      </div>

    </div>

    <Spinner
        :showSpinner="loading"
        :showMessage="loadingMessage"
        :infoMessage="detailMessage">
    </Spinner>
  </div>
</template>


<script>
import {
  apiCall,
  apiMultiPart,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  alertConfirm,
  alertPageMove,
  warningAlertPageMove,
  getMask,
  warpSite,
} from "@/common/utils";
import Tooltip from "@/xcost/home/components/Tooltip.vue";

export default {
  name: 'HowMuchCreate',
  components: {
    Tooltip
  },
  props: ['quoteToken'],
  data() {
    return {
      loading: true,
      loadingMessage: '견적 생성중',
      detailMessage: '',

      projectName: '',
      resources: [],
      fields: [
        { key: "CODE", label: "코드", thClass: "w15" },
        { key: "NAME", label: "품명", thClass: "w35" },
        { key: "STAD", label: "규격", thClass: "w30" },
        { key: "UNIT", label: "단위", thClass: "w10" },
        { key: "QUAN", label: "수량", thClass: "w10" },
        // { key: "REMARK", label: "비고", thClass: "w20" },
      ],

    };
  },
  computed: {
  },
  async beforeCreate() {
    // 여기서 실행될 Xcost Token으로 Web Token 형식을 맞춰 Token을 재생성하고 로그인하는 API 만들기
  },
  async created() {
    try {
      const r = await apiCall('POST', `/api/how-much/quote-token/analysis/${this.quoteToken}`);
      if (r.code === 200) {
        this.projectName = r.result.projectName;
        this.resources = r.result.resources;
        this.loading = false;
      }
      if (r.code === 401) {
        alert(r.message);
        window.location = '/';
      }
      if (r.code === 403) {
        alert(r.message);
        await this.userInfoVerify(r.result);
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async userTokenVerify() {
      try {
        const r = await apiCall('POST', `/api/how-much/`);

      } catch (e) {
        console.error(e);
      }
    },

    async userInfoVerify(user) {
      try {
        // 기존 회원정보 수정 폼 활용
        const params = {
          id: user.userId,
          no: user.userNo,
          hostName: window.location.hostname
        }

        const r = await apiCall('post', `/api/member/update-request`, params);

        // 강제 로그아웃
        this.$store.dispatch('userStore/LOGOUT');

        window.location = r.result.link;
      } catch (e) {
        console.error(e);
      }
    },
  }
};
</script>

<style scoped>

.items-table {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

</style>